import React from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";

import "./styles/ValidationItem.css";

export default function ValidationItem(props) {
    const { validation, user } = props;
    let message;

    if (validation.error) {
        message = `validations.errors.${validation.error.code}`;
    } else {
        message = null;
    }

    const loadingValidationStyle = {
        backgroundColor: "rgb(248, 248, 249)",
        border: "1px solid rgb(205, 205, 205)",
        color: "rgb(32, 32, 32)"
    }

    const errValidationStyle = {
        backgroundColor: "rgba(224, 180, 180, 0.2)",
        border: "1px solid rgb(224, 180, 180)",
        color: "rgb(145, 45, 45)"
    };

    const okValidationStyle = {
        backgroundColor: "rgba(163, 194, 147, 0.2)",
        border: "1px solid rgba(163, 194, 147, 1)",
        color: "rgb(26, 83, 26)"
    }

    let validationStyle;

    if (validation.loading || validation.valid === null) {
        validationStyle = loadingValidationStyle;
    } else {
        if (validation.valid) {
            validationStyle = okValidationStyle;
        } else {
            validationStyle = errValidationStyle;
        }
    }
    
    return (
        <div className="validation-item" style={validationStyle}>
            <div className="validation-item-header">
                { validation.code } { message && "/" } { message && <FormattedMessage id={message} /> }
            </div>
            <div className="validation-item-info">
                {
                    !validation.loading &&
                    <div>
                        { validation.updated_at && moment.utc(validation.updated_at).local().format("YYYY-MM-DD HH:mm:ss") }
                        &nbsp;/&nbsp;
                        { user.username }
                    </div>
                }

                {
                    validation.valid && validation.type === "ticket" &&
                    <div>
                        <FormattedMessage id="validations.types.blinijaTicket" />
                        &nbsp;/&nbsp;
                        { validation.ticket_data.from } - { validation.ticket_data.to}
                        &nbsp;/&nbsp;
                        <FormattedMessage
                            id="validations.arrived"
                            values={{
                                arrivalTime: moment(validation.ticket_data.arrival_time).format('YYYY-MM-DD HH:mm')
                            }}
                        />
                    </div>
                }

                {
                    validation.valid && validation.type === "coupon" &&
                    <div>
                        <FormattedMessage id="validations.types.coupon" />
                        &nbsp;/&nbsp;
                        { validation.coupon.name }
                        &nbsp;/&nbsp;
                        <FormattedMessage
                            id="validations.validFromTo"
                            values={{
                                validFrom: moment(validation.coupon.valid_from).format('YYYY-MM-DD HH:mm'),
                                validTo: moment(validation.coupon.valid_to).format('YYYY-MM-DD HH:mm')
                            }}
                        />
                    </div>
                }
            </div>
        </div>
    )
}