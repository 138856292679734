export default function fixValidationQueryKey(key) {
    switch (key) {
        case "dateFrom":
            return "valid_from_gte";
        
        case "dateTo":
            return "valid_to_lte";

        case "sort":
            return "_sort";

        default:
            return key;
    }
}