import React from 'react';
import { Redirect, Switch, Route, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { logout } from '../store/actions';
import Validations from './validations/Validations';
import Coupons from './coupons/Coupons';
import './styles/App.css';

function App(props) {
    return (
        <div className="App">
            <header className="header">
                <nav className="navigation">
                    <NavLink className="nav-link" to="/validations/validate">Tikrinti</NavLink>
                    <NavLink className="nav-link" exact to="/validations">Patikrinimai</NavLink>
                    {
                        (props.user.role.type === "authenticated" || props.user.role.type === "admin")
                        &&
                        <NavLink className="nav-link" exact to="/coupons">Kuponai</NavLink>
                    }
                </nav>
                <div className="user-menu">
                    <span>{ props.user.username }</span>
                    <div>
                        <button onClick={props.handleLogout}>Atsijungti</button>
                    </div>
                </div>
            </header>
            <Switch>
                <Route exact path="/" render={() => <Redirect to="/validations/validate" />} />
                <Route path="/validations" component={Validations} />
                <Route path="/coupons" component={Coupons} />
            </Switch>
        </div>
    );
}

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    handleLogout() {
        dispatch(logout());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
