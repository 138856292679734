import { useReducer } from "react";

const initialState = {
    header: "Galiojimas",
    valid: null,
    opened: false,
    highlight: false,
    options: [
        {
            id: 0,
            text: "Visi",
            headerText: "Galiojimas",
            selected: true,
            valid: null
        },
        {
            id: 1,
            text: "Galioja",
            selected: false,
            valid: true
        },
        {
            id: 2,
            text: "Negalioja",
            selected: false,
            valid: false
        },
    ],
}

function stateReducer(state, action) {
    switch (action.type) {
        case "TOGGLE":
            return {
                ...state,
                opened: !state.opened
            };

        case "OPTION":
            const item = action.payload;
            const options = state.options.map(option => {
                if (option.id === item.id) {
                    option.selected = true;
                } else {
                    option.selected = false;
                }
                return option;
            });
            const header = item.hasOwnProperty("headerText") ? item.headerText : item.text;
            return {
                ...state,
                options,
                highlight: item.id > 0,
                valid: item.valid,
                header
            }

        default:
            return state;
    }
}

export default function useSelect(validationActions) {
    const [state, dispatch] = useReducer(stateReducer, initialState);

    function toggleMenu() {
        dispatch({type: "TOGGLE"});
    }

    function handleChange(payload) {
        dispatch({type: "OPTION", payload});
        validationActions.handleOptionsChange({
            name: "valid",
            value: payload.valid
        });
    }

    return [state, {toggleMenu, handleChange}];
}
