import React from "react";

export default function SelectMenu(props) {
    const { optionsState, optionsActions } = props;
    return (
        <div>
            <p className="select-menu-label">{ props.label }</p>
            <div
                className={`select-menu ${optionsState.opened ? "opened": ""}`}
                onClick={optionsActions.toggleMenu}
            >
                <div className={`select-menu-placeholder ${optionsState.highlight ? "highlight" : ""}`}>
                    { optionsState.header }
                </div>
                {
                    optionsState.opened &&
                    <ul className="options-list">
                        {
                            optionsState.options.map((item, ind) => (
                                <li
                                    className={`select-menu-item ${item.selected ? "selected" : ""}`}
                                    key={ind}
                                    onClick={() => optionsActions.handleChange(item)}
                                >
                                    { item.text }
                                </li>
                            ))
                        }
                    </ul>
                }
            </div>
        </div>
    )
}