const initialValidationState = {
    query: {
        "code": "",
        "dateFrom": "",
        "dateTo": "",
        "valid": null,
        "sort": "id:DESC"
    },
    submitQuery: true
};

const initialCouponState = {
    query: {
        "code": "",
        "dateFrom": "",
        "dateTo": "",
        "sort": "valid_from:DESC"
    },
    submitQuery: true
};

export { initialValidationState, initialCouponState};