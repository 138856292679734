import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Validation from "./Validation";
import ValidationsList from "./ValidationsList";

export default function Validations(props) {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path} component={ValidationsList} />
            <Route exact path={`${path}/validate`} component={Validation} />
        </Switch>
    )
}