import React from "react";
import { useIntl, FormattedMessage } from "react-intl";

import "./styles/LoginForm.css";
import Loader from "./Loader";

function LoginForm(props) {
    const intl = useIntl();

    return (
        <div className="login-window">
            <h2>
                <FormattedMessage id="login.title" />
            </h2>
            {
                props.form.error
                &&
                <div className="login-error-message">
                    <FormattedMessage id="login.errors.ERR_USER_INCORRECT"/>
                </div>
            }
            <form className="login-form">
                <input
                    type="text"
                    name="username"
                    placeholder={intl.formatMessage({id: 'login.username'})}
                    value={props.form.username}
                    autoComplete="off"
                    onChange={props.handleChange}
                />
                <input
                    type="password"
                    name="password"
                    placeholder={intl.formatMessage({id: 'login.password'})}
                    value={props.form.password}
                    autoComplete="off"
                    onChange={props.handleChange}
                />
                <button
                    className="login-btn"
                    onClick={props.handleLogin}
                >
                    {
                        props.form.pending
                        ?
                        <Loader />
                        :
                        <FormattedMessage id="login.loginButton" />
                    }
                </button>
            </form>
        </div>
    )
}

export default LoginForm;