import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import CurrentValidations from "./CurrentValidations";
import { createValidation } from "../../store/actions";
import "./styles/validation.css";

function Validation(props) {
    const intl = useIntl();
    const input = useRef();

    const [form, setForm] = useState({ code: "", error: false });

    function handleInputChange(event) {
        setForm({
            error: false,
            code: event.target.value.toUpperCase()
        });
    }
    
    function handleSubmit(event) {
        event.preventDefault();

        if (validateCode(form.code)) {
            props.handleValidate(form.code);
            setForm({
                error: false,
                code: ""
            });
        } else {
            setForm({
                error: true,
                code: ""
            });
        }
    }

    function validateCode(code) {
        return code.length > 3;
    }

    useEffect(() => {
        input.current.focus();
    }, []);

    return (
        <div className="validation">
            <h1><FormattedMessage id="validator.title" /></h1>
            <form className="validation-form" onSubmit={handleSubmit}>
                <input
                    type="text"
                    onChange={handleInputChange}
                    value={form.code}
                    autoComplete="off"
                    className="validate-input"
                    ref={input}
                    placeholder={intl.formatMessage({id: "validations.codePlaceholder"})}
                />
                <button
                    className="validate-button"
                    type="submit"
                >
                    <span>
                        <FormattedMessage id="validations.createButton" />
                    </span>
                </button>
            </form>
            <CurrentValidations />
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    handleValidate(code) {
        const validationKey = moment().valueOf() + '_' + code;
        dispatch(createValidation(validationKey, code));
    },
})

export default connect(null, mapDispatchToProps)(Validation);