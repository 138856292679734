import { apiLogin } from './api';
import jwtDecode from 'jwt-decode';

export default {

    // TODO: Get user from local storage and set

    logout() {
        // TODO: Delete user value from local storage
        return new Promise((resolve, reject) => {
            resolve();
        });
    },

    async login(username, password) {
        try {
            const user = await apiLogin(username, password);
            return Object.assign(jwtDecode(user.jwt), user.user, { token: user.jwt });
        } catch (error) {
            throw error;
        }
    }
}
