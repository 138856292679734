import React from "react";
import { connect } from "react-redux";
import ValidationItem from "./ValidationItem";

import "./styles/CurrentValidationsList.css";

function CurrentValidations(props) {
    return (
        <div className="current-validations-list">
            {
                props.validations.map((item, ind) => (
                    <ValidationItem key={ind} validation={item} user={props.user} />
                ))
            }
        </div>
    );
}

const mapStateToProps = state => ({
    validations: state.validations.current,
    user: state.user,
});

export default connect(mapStateToProps)(CurrentValidations);