import React from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";

import FormInput from "../FormInput";
import ValidationItem from "./ValidationItem";
import { getValidationsList } from "../../store/actions";
import SelectMenu from "../SelectMenu";
import useSelect from "../../hooks/useSelect";
import useValidation from "../../hooks/useValidation";
import { initialValidationState } from "../../hooks/hooks-state/hooksState";
import "./styles/ValidationsList.css";
import "./styles/dropdown.css";

function ValidationsList(props) {
    const { loadValidations } = props;
    const intl = useIntl();
    const [validationState, validationActions] = useValidation("validations", loadValidations, initialValidationState);
    const [optionsState, optionsActions] = useSelect(validationActions);

    const validations = props.validations && props.validations.map((item, ind) => (
        <ValidationItem key={ind} validation={item} user={props.user} />
    ));

    return (
        <div className="validations">

            <form className="validations-form" onSubmit={validationActions.handleSubmit}>
                <FormInput
                    type="text"
                    name="code"
                    value={validationState.query.code}
                    onChange={validationActions.handleInputChange}
                    placeholder={intl.formatMessage({id: "validations.codePlaceholder"})}
                    label={intl.formatMessage({id: "validations.code"})}
                />
                <FormInput
                    type="text"
                    name="dateFrom"
                    value={validationState.query.dateFrom}
                    onChange={validationActions.handleInputChange}
                    placeholder={intl.formatMessage({id: "app.form.fields.dateFromPlaceholder"})}
                    label={intl.formatMessage({id: "app.form.fields.dateFrom"})}
                />
                <FormInput
                    type="text"
                    name="dateTo"
                    value={validationState.query.dateTo}
                    onChange={validationActions.handleInputChange}
                    placeholder={intl.formatMessage({id: "app.form.fields.dateToPlaceholder"})}
                    label={intl.formatMessage({id: "app.form.fields.dateTo"})}
                />
                <SelectMenu
                    optionsState={optionsState}
                    optionsActions={optionsActions}
                    label={intl.formatMessage({id: "validations.validity"})}
                    intl={intl}
                />
                <button className="filter-button" type="submit">Filtruoti</button>
            </form>
            <div className="validations-list">
                {
                    validations
                }
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    validations: state.validations.list.items,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    loadValidations(query) {
        dispatch(getValidationsList(query));
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ValidationsList);