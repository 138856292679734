import { useReducer, useEffect } from "react";
import pickQueryFix from "../utils/pickQueryFix";

function queryReducer(state, action) {
    switch (action.type) {
        case "QUERY":
            const { name, value } = action.payload;
            if (state.query.hasOwnProperty(name)) {
                return {
                    ...state,
                    query: Object.assign(
                        {},
                        state.query,
                        { [name]: value }
                    )
                };
            } else {
                return state;
            }
        
        case "SUBMIT":
            return {
                ...state,
                submitQuery: action.payload
            }

        default:
            return state;
    }
    
}

export default function useValidation(formType, loadItems, initialValidationState) {
    const fixQueryKey = pickQueryFix(formType);
    const [state, dispatch] = useReducer(queryReducer, initialValidationState);
    
    useEffect(() => {
        
        if (state.submitQuery) {
            const queryList = Object.entries(state.query).filter(
                ([key, value]) => value !== null && value !== ""
            ).map((
                [key, value]) => {
                    const fixedKey = fixQueryKey(key);
                    return `${fixedKey}=${value}`;
                }
            );

            const queryString = queryList.join("&");
            loadItems(queryString);

            dispatch({
                type: "SUBMIT",
                payload: false
            });
        }
    }, [fixQueryKey, loadItems, state.submitQuery, state.query]);

    function handleInputChange(event) {
        const { name, value } = event.target;
        dispatch({
            type: "QUERY",
            payload: {
                name,
                value: value.toUpperCase()
            }
        });
    }
    
    function handleSubmit(event) {
        event.preventDefault();
        dispatch({
            type: "SUBMIT",
            payload: true
        })
    }

    function handleOptionsChange(payload) {
        dispatch({
            type: "QUERY",
            payload
        })
    }

    const actions = {
        handleInputChange,
        handleOptionsChange,
        handleSubmit
    };

    return [state, actions];
};