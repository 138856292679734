import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { login } from "../store/actions/user";
import LoginForm from "./LoginForm";
import { connect } from "react-redux";

function Login(props) {
    const initialState = {
        username: "",
        password: "",
        pending: false,
        error: null,
        success: null
    };
    const [form, setForm] = useState(initialState);

    useEffect(() => {
        setForm(form => ({
            ...form,
            pending: false,
            error: props.user.error,
            password: ""
        }));
    }, [props.user.error]);

    function handleInputChange(event) {
        const { value, name } = event.target;
        setForm(form => ({
            ...form,
            error: null,
            [name]: value
        }));
    }

    function handleLogin(event) {
        event.preventDefault();
        setForm(form => ({
            ...form,
            pending: true
        }));
        props.handleLogin(form.username, form.password);
    }
    
    if ( props.user.loggedIn ) {
        return <Redirect to="/" />;
    }

    return (
        <LoginForm
            form={form}
            handleChange={handleInputChange}
            handleLogin={handleLogin}
        />
    );
};

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    handleLogin(username, password) {
        dispatch(login(username, password));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);