export default function fixValidationQueryKey(key) {
    switch (key) {
        case "dateFrom":
            return "updated_at_gte";
        
        case "dateTo":
            return "updated_at_lte";

        case "sort":
            return "_sort";

        default:
            return key;
    }
}