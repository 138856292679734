import React from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";

export default function CouponItem(props) {
    const { coupon } = props;
    const defaultCouponStyle = {
        backgroundColor: "rgb(248, 248, 249)",
        border: "1px solid rgb(205, 205, 205)",
        color: "rgb(32, 32, 32)"
    };

    const validatedCouponStyle = {
        backgroundColor: "rgba(163, 194, 147, 0.2)",
        border: "1px solid rgba(163, 194, 147, 1)",
        color: "rgb(26, 83, 26)"
    };
    
    const couponStyle = props.validated
        ? validatedCouponStyle
        : defaultCouponStyle;
    
    return (
        <div className="validation-item" style={couponStyle}>
            <div className="validation-item-header">
                { coupon.code } / { coupon.type }
            </div>
            <div className="validation-item-info">
                <div>{ coupon.name }</div>
                {
                    props.validated &&
                    <div>
                        <FormattedMessage id="coupons.used" />
                        &nbsp; <strong> { moment.utc(coupon.updated_at).local().format('YYYY-MM-DD HH:mm:ss') } </strong>
                        &nbsp; / { props.user.username }
                    </div>
                }
                <div>
                        <FormattedMessage
                            id="coupons.validFromTo"
                            values={{
                                validFrom: moment(coupon.valid_from).local().format('YYYY-MM-DD HH:mm'),
                                validTo: moment(coupon.valid_to).local().format('YYYY-MM-DD HH:mm')
                            }}
                        />
                </div>
            </div>
        </div>
    );
}