export default {
    errors: {
        ERR_SYSTEM: 'Sisteminė klaida'
    },
    app: {
        form: {
            fields: {
                dateFrom: 'Data nuo',
                dateFromPlaceholder: 'Data nuo',
                dateTo: 'Data iki',
                dateToPlaceholder: 'Data iki'
            },
            values: {
                all: 'Visi'
            },
            filter: {
                submit: 'Filtruoti'
            }
        }
    },
    login: {
        title: 'Prisijunkite',
        username: 'Vartotojo vardas',
        password: 'Slaptažodis',
        loginButton: 'Prisijungti',
        errors: {
            ERR_USER_INCORRECT: 'Neteisingas vartotojo vardas arba slaptažodis'
        }
    },
    menu: {
        validator: 'Tikrinti',
        validations: 'Patikrinimai',
        coupons: 'Kuponai',
        users: 'Vartotojai'
    },
    validator: {
        title: 'Kupono tikrinimas'
    },
    validations: {
        title: 'Patikrinimai',
        errors: {
            serviceUnavailable: 'Nepavyko patikrinti, bandykite dar kartą',
            ERR_SYSTEM: 'Nepavyko patikrinti, sistemos klaida',
            ERR_VALIDATIONS_NOT_FOUND: 'Nėra tokio bilieto arba kodo',
            ERR_VALIDATIONS_VALIDATED: 'Kodas jau panaudotas',
            ERR_NOT_VALID: 'Bilietas ar kodas nebegalioja arba neatitinka sąlygų'
        },
        types: {
            blinijaTicket: 'Autobuso bilietas',
            coupon: 'Kuponas'
        },
        code: 'Kodas',
        codePlaceholder: 'A000000001 A1',
        validity: 'Galiojimas',
        validityPlaceholder: 'Galiojimas',
        createButton: 'Tikrinti',
        valid: 'Galioja',
        invalid: 'Negalioja',
        validFromTo: 'Galioja nuo {validFrom} iki {validTo}',
        arrived: 'Atvyko {arrivalTime}'
    },
    coupons: {
        title: 'Kuponai',
        used: 'Panaudotas',
        validFrom: 'Galioja nuo',
        validFromPlaceholder: 'Galioja nuo',
        validTo: 'Galioja iki',
        validToPlaceholder: 'Galioja iki',
        validFromTo: 'Galioja nuo {validFrom} iki {validTo}',
        code: 'Kupono kodas',
        codePlaceholder: 'A000000001 A1',

    }
}