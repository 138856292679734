import { combineReducers } from "redux";

import actionTypes from "../actionTypes";

function validationsListReducer(validations = {}, action) {
    switch (action.type) {
        case actionTypes.VALIDATIONS_LIST_REQUEST:
            return {
                filter: action.payload,
                loading: true
            };

        case actionTypes.VALIDATIONS_LIST_SUCCESS:
            return Object.assign({}, validations, {
                loading: false,
                items: action.payload.items,
                itemsTotal: action.payload.itemsTotal
            });

        case actionTypes.VALIDATIONS_LIST_FAILURE:
            return Object.assign({}, {
                filter: validations.filter,
                loading: false,
                error: action.error
            });

        default:
            return validations;
    }
};

function currentValidationsListReducer(validations = [], action) {
    switch (action.type) {
        case actionTypes.VALIDATIONS_CREATE_REQUEST:
            return [
                {
                    key: action.meta.key,
                    loading: true,
                    code: action.payload
                },
                ...validations
            ];

        case actionTypes.VALIDATIONS_CREATE_SUCCESS:
            return validations.map(item => {
                if (item.key !== action.meta.key) {
                    return item;
                }

                return {
                    ...item,
                    loading: false,
                    ...action.payload
                };
            });

        case actionTypes.VALIDATIONS_CREATE_FAILURE:
            return validations.map(item => {
                if (item.key !== action.meta.key) {
                    return item;
                }

                return {
                    ...item,
                    loading: false,
                    processingError: action.payload
                };
            });

        default:
            return validations;
    }
};

export default combineReducers({
    list: validationsListReducer,
    current: currentValidationsListReducer
});