import { createAction } from "../../utils/utils";
import actionTypes from "../actionTypes";
import { apiCouponsCreate, apiCouponsList } from "../../services/api";

const couponAction = {
    create: {
        request: (data) => createAction(actionTypes.COUPONS_CREATE_REQUEST, data, undefined),
        success: (result) => createAction(actionTypes.COUPONS_CREATE_SUCCESS, result, undefined),
        failure: (error) => createAction(actionTypes.COUPONS_CREATE_FAILURE, error, true)
    },

    list: {
        request: (filters = {}) => createAction(actionTypes.COUPONS_LIST_REQUEST, filters),
        success: (coupons) => createAction(actionTypes.COUPONS_LIST_SUCCESS, coupons),
        failure: (error) => createAction(actionTypes.COUPONS_LIST_FAILURE, error, true)
    }
};

function createCoupon(action) {
    return async dispatch => {
        try {
            dispatch(couponAction.create.request(action.payload));
            const coupon = await apiCouponsCreate(action.payload);
            dispatch(couponAction.create.success(coupon));
        } catch (error) {
            dispatch(couponAction.create.failure(error));
        }
    }
}

function getCouponsList(query) {
    return async dispatch => {
        try {
            const response = await apiCouponsList(query);
            const data = {
                items: response,
                itemsTotal: response.length
            };
            dispatch(couponAction.list.success(data));
        } catch (error) {
            dispatch(couponAction.list.failure(error.message));
        }
    }
}

export { couponAction, createCoupon, getCouponsList };