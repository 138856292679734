function createAction(type, payload = undefined,
    error = undefined, meta = undefined
    ) {
    return { type, payload, error, meta }
};

function createActionRequestTypes(base) {
    let module, name;
    const slash = base.indexOf('/');

    if (slash === -1) { // not found
        module = '';
        name = base;

    } else {
        module = base.substring(0, slash);
        name = base.substring(slash + 1);
    }

    return {
        [`${module}_${name}_REQUEST`]: `${module}/${name}_REQUEST`,
        [`${module}_${name}_SUCCESS`]: `${module}/${name}_SUCCESS`,
        [`${module}_${name}_FAILURE`]: `${module}/${name}_FAILURE`,
    };
};

export { createAction, createActionRequestTypes };