import { createAction } from "../../utils/utils";
import actionTypes from "../actionTypes";
import { apiValidationsCreate, apiValidationsList } from "../../services/api";

const validationAction = {
    create: {
        request: (key, code) => createAction(actionTypes.VALIDATIONS_CREATE_REQUEST, code, undefined, {key}),
        success: (key, result) => createAction(actionTypes.VALIDATIONS_CREATE_SUCCESS, result, undefined, {key}),
        failure: (key, error) => createAction(actionTypes.VALIDATIONS_CREATE_FAILURE, error, true, {key})
    },

    list: {
        request: (filters = {}) => createAction(actionTypes.VALIDATIONS_LIST_REQUEST, filters),
        success: (validations) => createAction(actionTypes.VALIDATIONS_LIST_SUCCESS, validations),
        failure: (error) => createAction(actionTypes.VALIDATIONS_LIST_FAILURE, error, true)
    }
};

function createValidation(key, code) {
    return async dispatch => {
        try {
            dispatch(validationAction.create.request(key, code));
            const validation = await apiValidationsCreate(code);
            dispatch(validationAction.create.success(key, validation))
        } catch (error) {
            dispatch(validationAction.create.failure(key, error.message));
        }
    }
}

function getValidationsList(query) {
    return async dispatch => {
        try {
            const response = await apiValidationsList(query);
            const data = {
                items: response,
                itemsTotal: response.length
            };
            dispatch(validationAction.list.success(data));
        } catch (error) {
            dispatch(validationAction.list.failure(error.message));
        }
    }
}

export { validationAction, createValidation, getValidationsList };