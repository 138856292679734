import React from "react";

import "./styles/FormInput.css";

export default function FormInput(props) {
    return (
        <div className="form-input">
            <p>
                { props.label }
            </p>
            {
                React.cloneElement(
                    <input />,
                    {...props}
                )
            }
        </div>
    )
}