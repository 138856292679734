import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import App from './App';
import LoginContainer from './Login';
import { logout } from '../store/actions';

function Root(props) {
    const { user, logout } = props;
    let component;

    const now = Math.floor(new Date().getTime() / 1000);

    if (!user.loggedIn) {
        component = <Redirect to="/login" />;
    } else {
        const userExpired = now > user.exp;

        if (userExpired) {
            logout();
        } else {
            component = <Route path="/" component={App} />
        }
    }

    return (
        <div>
            { component }
            <Route path="/login" component={LoginContainer}/>
        </div>
    );
};

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
    async logout() {
        await dispatch(logout());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Root);