import { combineReducers } from "redux";

import coupons from "./coupons";
import user from "./user";
import validations from "./validation";

export default combineReducers({
    coupons,
    user,
    validations
});