import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";

import FormInput from "../FormInput";
import CouponItem from "./CouponItem";

import { getCouponsList, getValidationsList } from "../../store/actions";
import useValidation from "../../hooks/useValidation";
import { initialCouponState } from "../../hooks/hooks-state/hooksState";
import "./styles/Coupon.css";

function Coupons(props) {
    const { loadValidations, validations } = props;
    const intl = useIntl();
    const [couponState, couponActions] = useValidation("coupon", props.loadCoupons, initialCouponState);

    useEffect(() => {
        if (!validations) {
            loadValidations("_sort=id:DESC");
        }
    }, [loadValidations, validations]);

    let coupons;
    
    if (props.coupons && props.validations) {
        coupons = props.coupons.map((item, ind) => {
            const validation = props.validations.filter(i => i.code === item.code);
            const validated = validation.length > 0;
            return <CouponItem key={ind} coupon={item} validated={validated} user={props.user} />;
        });
    }

    return (
        <div className="coupons">
            <form className="coupons-form" onSubmit={couponActions.handleSubmit}>
                <FormInput
                    type="text"
                    name="code"
                    value={couponState.query.code}
                    onChange={couponActions.handleInputChange}
                    placeholder={intl.formatMessage({id: "coupons.codePlaceholder"})}
                    label={intl.formatMessage({id: "coupons.code"})}
                />
                <FormInput
                    type="text"
                    name="dateFrom"
                    value={couponState.query.dateFrom}
                    onChange={couponActions.handleInputChange}
                    placeholder={intl.formatMessage({id: "coupons.validFromPlaceholder"})}
                    label={intl.formatMessage({id: "coupons.validFrom"})}
                />
                <FormInput
                    type="text"
                    name="dateTo"
                    value={couponState.query.dateTo}
                    onChange={couponActions.handleInputChange}
                    placeholder={intl.formatMessage({id: "coupons.validToPlaceholder"})}
                    label={intl.formatMessage({id: "coupons.validTo"})}
                />
                <button className="filter-button" type="submit">Filtruoti</button>
            </form>
            <div className="coupons-list">
                {
                    coupons
                }
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    coupons: state.coupons.items,
    validations: state.validations.list.items,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    loadCoupons(query) {
        dispatch(getCouponsList(query));
    },
    loadValidations(query) {
        dispatch(getValidationsList(query));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Coupons);