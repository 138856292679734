import actionTypes from "../actionTypes";

export default function couponsReducer(coupons = {}, action) {
    switch (action.type) {
        case actionTypes.COUPONS_LIST_REQUEST:
            return {
                filter: action.payload,
                loading: true
            };

        case actionTypes.COUPONS_LIST_SUCCESS:
            return Object.assign({}, coupons, {
                loading: false,
                items: action.payload.items,
                itemsTotal: action.payload.itemsTotal
            });

        case actionTypes.COUPONS_LIST_FAILURE:
            return Object.assign({}, {
                filter: coupons.filter,
                loading: false,
                error: action.error
            });

        default:
            return coupons;
    }
};