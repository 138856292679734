import React from 'react';
import ReactDOM from 'react-dom';
import { Provider }  from "react-redux";
import { BrowserRouter, Route } from "react-router-dom";
import { IntlProvider } from "react-intl";
import * as serviceWorker from './serviceWorker';

import locales from "./resources/locales";
import flattenMessages from "./utils/flattenMessages";
import store from "./store/store";
import './index.css';
import Root from './components/Root';

ReactDOM.render(
    <Provider store={store}>
        <IntlProvider locale="lt" messages={flattenMessages(locales)}>
            <BrowserRouter>
                <Route component={Root} />
            </BrowserRouter>
        </IntlProvider>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
