import moment from "moment";
import actionTypes from "../actionTypes";

const localStorageUserKey = 'userdata';

export default function userReducer(user, action) {

    if (user === undefined) {
        const localStorageUser = localStorage.getItem(localStorageUserKey);
        if (localStorageUser !== null) {
            return JSON.parse(localStorageUser);
        }

        return {};
    }

    let newUser;

    switch (action.type) {
        case actionTypes.USERS_LOGIN_REQUEST:
            return {
                loggedIn: false,
                error: null
            };

        case actionTypes.USERS_LOGIN_SUCCESS:
            newUser = {
                loggedIn: true,
                loggedInAt: moment().format('YYYY-MM-DD HH:mm:ss'),
                ...action.payload
            };
            localStorage.setItem(localStorageUserKey, JSON.stringify(newUser));
            return newUser;

        case actionTypes.USERS_LOGIN_FAILURE:
            return {
                loggedIn: false,
                error: action.payload.message
            };

        case actionTypes.USERS_LOGOUT_REQUEST:
            newUser = { loggedIn: false };
            localStorage.setItem(localStorageUserKey, JSON.stringify(newUser));
            return newUser;

        case actionTypes.USERS_LOGOUT_SUCCESS:
            return Object.assign({}, user, {errors: null});
            
        case actionTypes.USERS_LOGOUT_FAILURE:
            return Object.assign({}, user, {error: action.payload});
        default:
            return user;
    }

};