import config from '../resources/config';
import store from "../store/store";
import { logout } from "../store/actions";

async function callApi(endpoint, options = {}) {
    const fullUrl = (endpoint.indexOf(config.api.root) ? config.api.root + endpoint : endpoint);
    const token = store.getState().user.token;

    const headers = { 'Content-Type': 'application/json' };

    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    options = Object.assign(options, {
        headers: headers
    });

    try {
        const response = await fetch(fullUrl, options);
        const data = await response.json();

        if (!response.ok) {
            if (response.status === 403) {
                store.dispatch(logout());
            }

            if (data.code) {
                throw new Error(data.code);
            }

            if (data.message) {
                // data.message is array of objects
                // object has a "messages" field that holds array of objects
                // get first object which has "id" and "message" fields
                const [ { messages: [ message ] } ] = data.message;
                throw new Error(message.id);
            }
            
            throw new Error("ERR_SYSTEM");
        }

        return data;
    } catch (error) {
        throw error;
    }
};

export function apiLogin(username, password) {
    return callApi('auth/local', {
        method: 'POST',
        body: JSON.stringify({ identifier: username, password})
    });
};

export function apiValidationsList(query) {
    return callApi(`validations?${query}`);
}

export function apiValidationsCreate(code) {
    return callApi('validations', {
        method: 'POST',
        body: JSON.stringify({ code })
    });
}

export function apiCouponsList(query) {
    return callApi(`coupons?${query}`);
}

export function apiCouponsCreate(data) {
    return callApi('coupons', {
        method: 'POST',
        body: JSON.stringify({ data })
    });
}