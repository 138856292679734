const env = process.env.NODE_ENV || 'development';
let config;

if (env === 'production') {
    config = {
        api: {
            root: `//${window.location.hostname}/api/`
        }
    }
} else {
    config = {
        api: {
            root: `//${window.location.hostname}:1337/`
        }
    }
}

export default config;