import { createAction } from "../../utils/utils";
import actionTypes from "../actionTypes";
import auth from "../../services/auth";

const userAction = {
    login: {
        request: () => createAction(actionTypes.USERS_LOGIN_REQUEST),
        success: (user) => createAction(actionTypes.USERS_LOGIN_SUCCESS, user),
        failure: (error) => createAction(actionTypes.USERS_LOGIN_FAILURE, error, true)
    },
    logout: {
        request: () => createAction(actionTypes.USERS_LOGOUT_REQUEST),
        success: () => createAction(actionTypes.USERS_LOGOUT_SUCCESS),
        // success: () => window.location.reload(),
        failure: (error) => createAction(actionTypes.USERS_LOGOUT_FAILURE, error, true)
    }
};

function login(username, password) {
    return async dispatch => {
        try {
            dispatch(userAction.login.request());
            const user = await auth.login(username, password);
            dispatch(userAction.login.success(user));
        } catch (error) {
            dispatch(userAction.login.failure(error));
        }
    }
}

function logout() {
    return async dispatch => {
        try {
            dispatch(userAction.logout.request());
            await auth.logout();
            dispatch(userAction.logout.success());
        } catch (error) {
            dispatch(userAction.logout.failure(error));
        }
    }
}

export { userAction, login ,logout };